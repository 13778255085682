/**
 * Part of Genesis application
 *
 * @copyright 2019 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {assignIn, cloneDeep} from 'lodash'
import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from 'buefy'
import axios from 'axios'
import Gps from './services/gps'
import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const HTTP_STATUS_CODE_UNAUTHORIZED = 401
const HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY = 422
const HTTP_STATUS_CODE_CONFLICT = 409

const state = {
    i18n: null,
    gpsService: null,
    modals: {
        'feedback-modal': {
            isActive: false,
            params: null,
        },
        'comment-modal': {
            isActive: false,
            params: null,
        },
        'complete-modal': {
            isActive: false,
            params: null,
        },
        'confirmation-modal': {
            isActive: false,
            params: null,
        },
        'freight-modal': {
            isActive: false,
            params: null,
        },
        'grid-config-modal': {
            isActive: false,
            params: null,
        },
        'remark-modal': {
            isActive: false,
            params: null,
        },
        'lading-bill-modal': {
            isActive: false,
            params: null,
        },
        'load-tender-response-modal': {
            isActive: false,
            params: null,
        },
        'freight-confirmation-modal': {
            isActive: false,
            params: null,
        },
        'freight-trip-comments-modal': {
            isActive: false,
            params: null,
        },
        'freight-trip-create-modal': {
            isActive: false,
            params: null,
        },
        'cancel-freight-trip-confirmation-modal': {
            isActive: false,
            params: null,
        },
        'complete-freight-confirmation-modal': {
            isActive: false,
            params: null,
        },
        'finalize-freight-confirmation-modal': {
            isActive: false,
            params: null,
        },
    },
    validationErrors: {},
    viewModelData: null,
    windowCounter: 0,
    windows: [],
}

export const mutations = {
    activateModal (state, target) {
        state.modals[target].isActive = true
    },
    addWindow(state, value) {
        state.windows.push(value)
    },
    clearModalParams (state, target) {
        state.modals[target].params = null
    },
    clearValidationError(state, field) {
        const validationErrors = cloneDeep(state.validationErrors)
        delete validationErrors[field]

        state.validationErrors = validationErrors
    },
    deactivateModal (state, target) {
        state.modals[target].isActive = false
    },
    incrementWindowCounter(state) {
        state.windowCounter++
    },
    removeWindow(state, name) {
        state.windows = state.windows.filter(w => w.name !== name)
    },
    setFreightTemplateInCatalogByIndex(state, payload) {
        state.viewModelData.freightCatalogs[Laravel.CatalogType.FreightTemplate][payload.index] = payload.value
    },
    setI18n (state, i18n) {
        state.i18n = i18n
    },
    setGpsService(state, gpsService) {
        state.gpsService = gpsService
    },
    setModalParams (state, data) {
        state.modals[data.target].params = data.params
    },
    setValidationErrors (state, errors) {
        state.validationErrors = errors
    },
    setViewModelData: (state, viewModelData) => state.viewModelData = viewModelData,
    setViewModelDataProperty: (state, payload) => {
        state.viewModelData = Object.assign({}, state.viewModelData, {[payload.property]: payload.value})
    },
}

export const actions = {
    closeModal: function ({ commit }, target) {
        document.documentElement.classList.remove('is-clipped')
        commit('deactivateModal', target)
        commit('clearModalParams', target)
    },
    closeWindow({ state, commit }, name) {
        const window = state.windows.find(w => w.name === name)
        if (window) {
            window.close()
            commit('removeWindow', name)
        }
    },
    closeAllWindows({ state, commit }) {
        state.windows.forEach(w => {
            if (! w.closed) {
                w.close()
            }

            commit('removeWindow', w.name)
        })
    },
    doReloadPage: function () {
        window.location.reload(true)
    },
    async fetchViewModelProperty({ commit, state, dispatch }, payload) {
        try {
            const response = await axios({
                method: 'GET',
                url: payload.url,
                responseType: 'JSON',
            })
            const property = { property: payload.property, value: response.data }
            commit('setViewModelDataProperty', property)
            return response.data
        } catch (error) {
            const context = {
                error: error,
                message: state.i18n.t(payload.errorMessageKey),
            }
            dispatch('handleAjaxError', context)
            throw context
        }
    },
    handleAjaxError: async function ({ commit, dispatch, state }, data) {
        let rethrow = false
        let response
        let request
        if (data.error.response) {
            response = data.error.response
            if (! Laravel.genesisConfiguration.test_mode) {
                console.info('Error has response data:\n%o', response)
            }
            if (data.error.response.status === HTTP_STATUS_CODE_UNAUTHORIZED) {
                dispatch('doReloadPage')
                return
            } else if (data.error.response.status === HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY) {
                commit('setValidationErrors', data.error.response.data.errors)
            } else if (data.error.response.status === HTTP_STATUS_CODE_CONFLICT) {
                data.message = data.error.response.data.message
                data.message_key = null
            } else {
                rethrow = Laravel.rethrow === undefined ? true : Laravel.rethrow
            }
        } else {
            rethrow = Laravel.rethrow === undefined ? true : Laravel.rethrow
            if (data.error.request) {
                request = data.error.request
                console.info('Error has request:\n%o', request)
            }
        }

        const detail_messages = Array.isArray(data.details) ? data.details.join(' ') : ''
        const message = ((data.message_key ? state.i18n.t(data.message_key) : data.message) + ' ' + detail_messages)
            .trim()
        if (data.showAlert) {
            data.showAlert(message)
        } else {
            const configuration = {
                message: message,
                type: 'is-danger',
            }

            assignIn(
                configuration,
                data.configuration && typeof data.configuration === 'object' ? data.configuration : {},
            )
            await dispatch('showAlert', configuration)
        }

        if (rethrow) {
            const error = {
                data,
                message,
                request: { ...request },
                response: { ...response },
            }
            console.error('Unexpected error:\n%o', error)
            throw data.error
        }
    },
    async initGpsService({commit, state}) {
        if (state.gpsService !== null) {
            return
        }

        let service = new Gps()
        service = await service.initSession()
        commit('setGpsService', service)
    },
    async closeGpsService({commit, state}) {
        if (state.gpsService === null) {
            return
        }

        await state.gpsService.closeSession()
        commit('setGpsService', null)
    },
    openConfirmDialog({ state }, params) {
        let confirmParams = assignIn({
            cancelText: state.i18n.t('No'),
            confirmText: state.i18n.t('Yes'),
        }, params)

        Dialog.confirm(confirmParams)
    },
    openModal: function ({ commit }, target) {
        document.documentElement.classList.add('is-clipped')
        commit('activateModal', target)
    },
    openModalWithParams({ commit }, payload) {
        document.documentElement.classList.add('is-clipped')
        commit('activateModal', payload.target)
        commit('setModalParams', payload)
        commit('setValidationErrors', {})
    },
    openWindow({state, commit}, payload) {
        commit('incrementWindowCounter')
        const window = payload.window.open(
            payload.route.href,
            `genesis.${payload.name}-${state.windowCounter}`,
            'resizable,width=1024,height=633',
        )
        commit('addWindow', window)
    },
    /* eslint-disable-next-line no-unused-vars*/
    redirectTo: function (context, target) {
        /*global router*/
        if (router.currentRoute.name !== target) {
            router.push({ name: target})
        }
    },
    /* eslint-disable-next-line no-empty-pattern*/
    setLayout({}, payload) {
        document.querySelector('body').classList.toggle('is-height100pct', payload.is_full_height)
        document.querySelector('body>div#app').classList.toggle('is-height100pct', payload.is_full_height)
        document.querySelector('body>div#app>div').classList.toggle('is-height100pct', payload.is_full_height)
        document.querySelector('body>div#app>div>div').classList.toggle('is-height100pct', payload.is_full_height)
        document.querySelector('body>div#app>div>div>section').classList.toggle('is-height100pct', payload.is_full_height)
        document.querySelector('body>div#app>div>div>section').classList.toggle('section', ! payload.is_full_height)
    },
    /* eslint-disable-next-line no-unused-vars*/
    showAlert: function (context, payload) {
        const is_message = typeof payload === 'string'
        const defaultConfiguration = {
            message: is_message ? payload : payload.message,
            position: 'is-top',
            type: 'is-success',
            duration: 4000,
            actionText: null,
        }

        assignIn(defaultConfiguration, is_message ? {} : payload)
        Toast.open(defaultConfiguration)
    },
}

export const getters = {
    getErrorMessage: (state) => (field) => {
        if (Object.prototype.hasOwnProperty.call(state.validationErrors, field)) {
            return state.validationErrors[field].join(' ')
        }

        return ''
    },
    getModalParams: (state) => (target) => {
        return state.modals[target].params
    },
    hasValidationErrors: (state) => (field) => {
        return Object.prototype.hasOwnProperty.call(state.validationErrors, field)
    },
    isModalActive: (state) => (target) => {
        return state.modals[target].isActive
    },
}

export const StoreSource = {
    actions,
    getters,
    mutations,
    state,
}

export default new Vuex.Store(StoreSource)
