/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import './bootstrap'
import {CatalogModule} from './modules/CatalogModule'
import {BreadcrumbModule} from './modules/BreadcrumbModule.js'
import {
    Button,
    Checkbox,
    Collapse as BCollapse,
    Dropdown,
    Field,
    Icon,
    Input,
    Modal as BModal,
    Radio,
    Switch,
    Table,
    Tabs,
    Toast,
    Upload,
} from 'buefy'
import {EventModule} from './modules/EventModule'
import {faShield, faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons'
import {faTruck, faAngleDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {i18n, loadLocaleAsync} from './setup/i18n-setup.js'
import {LicenseManager} from '@ag-grid-enterprise/core'
import {sync} from 'vuex-router-sync'
import Application from './components/Application'
import axios from 'axios'
import LControlFullscreen from 'vue2-leaflet-fullscreen'
import Permissions from './mixins/Permissions.vue'
import router from './routes.js'
import store from './store.js'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

const Vue = window.Vue = require('vue').default

library.add(faTruck, faShield, faAngleDown, faMapMarkerAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

LicenseManager.setLicenseKey(process.env.MIX_AG_GRID_LICENSE_KEY)
window.loadLocaleAsync = loadLocaleAsync
Vue.use(Button)
Vue.use(BCollapse)
Vue.use(BModal)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(Tabs)
Vue.use(Table)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Vuex)
Vue.mixin(Permissions)
Vue.use(VueRouter)
Vue.component('l-control-fullscreen', LControlFullscreen)

sync(store, router)
let viewModel = (document.getElementById('viewModelData') || {}).innerHTML
if (viewModel) {
    store.commit('setViewModelData', JSON.parse(viewModel))
}

store.commit('setI18n', i18n)
store.registerModule('BreadcrumbModule', BreadcrumbModule)
store.registerModule('CatalogModule', CatalogModule)
store.registerModule('EventModule', EventModule)
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
}

const app = new Vue({
    el: '#app',
    store,
    router,
    components: {
        Application,
        CustomerGroup: () => import('./customer_group/components/CustomerGroup.vue'),
    },
    watch: {
        '$store.state.route.name': async function (newValue) {
            const fullHeightRoutes = [
                'freight_movements',
                'freights.index',
                'localities.index',
                'queries.account_statement',
                'queries.customer_account_statement',
                'queries.invoice_balance_aging',
                'queries.daily_billing',
                'queries.freight_trips_by_vehicle',
                'queries.freight_trips_per_invoice',
                'queries.invoices_by_vehicle',
                'queries.invoice_payments',
            ]
            await this.$store.dispatch('setLayout', {is_full_height: fullHeightRoutes.includes(newValue)})
            this.$store.commit('setValidationErrors', {})
            const title = i18n.t(`routes.${newValue}`)
            const app_name = this.$refs.application.$props.appName
            await this.$nextTick(function () {
                const titleElement = document.querySelector('head>title')
                if (null === titleElement || undefined === titleElement) {
                    console.warn('Could not get title element!')
                    return
                }
                titleElement.innerHTML = `${app_name} - ${title}`
            })
        },
    },
    i18n,
    methods: {
        onFreightCreated(freight, name) {
            this.$store.dispatch('EventModule/dispatchEvent', {type: 'onFreightCreated', params: [freight, name]})
        },
        onLoadTenderRejected(loadTender, name) {
            this.$store.dispatch('EventModule/dispatchEvent', {
                type: 'onLoadTenderRejected',
                params: [loadTender, name],
            })
        },
    },
})
window.addEventListener('beforeunload', async () => {
    await app.$store.dispatch('closeAllWindows')
})

window.onFreightCreated = function (freight, name) {
    app.onFreightCreated(freight, name)
}
window.onLoadTenderRejected = function (loadTender, name) {
    app.onLoadTenderRejected(loadTender, name)
}

document.addEventListener('DOMContentLoaded', function () {
    console.info('Genesis app', app.$data)
    const $dropdowns = getAll('.dropdown:not(.is-hoverable)')

    if ($dropdowns.length > 0) {
        $dropdowns.forEach(function ($el) {
            $el.addEventListener('click', function (event) {
                event.stopPropagation()
                $el.classList.toggle('is-active')
            })
        })
    }

    const $burgers = getAll('.burger')

    if ($burgers.length > 0) {
        $burgers.forEach(function ($el) {
            $el.addEventListener('click', function () {
                const target = $el.dataset.target
                const $target = document.getElementById(target)
                $el.classList.toggle('is-active')
                $target.classList.toggle('is-active')
            })
        })
    }

    function getAll(selector) {
        return Array.prototype.slice.call(document.querySelectorAll(selector), 0)
    }
})
