/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

/**
 * @typedef {Object} ShowExchangeRateParms
 * @property {Date} date The date to get exchange rate for.
 * @property {string} currency_code The currency code to get exchange rate for.
 *
 * @typedef {Object} ShowExchangeRateResult
 * @property {string} date Exchange rate date string (YYYY-MM-DD).
 * @property {string} amount Exchange rate decimal amount with 6 digits of precision.
 */


export const ExchangeRate = {
    async getCurrentExchangeRate() {
        const response = await axios.get('/api/exchange_rates/current_value', {responseType: 'json'})

        return response.data
    },
    /**
     * Get exchange rate for a given date and currency.
     * @param {ShowExchangeRateParms} params
     * @returns {Promise<ShowExchangeRateResult>}
     */
    async show(params) {
        const response = await axios.get('/api/exchange_rates', {params, responseType: 'json'})

        return response.data.data
    },
}

export default ExchangeRate
